export const questionSections = [
  {
    type: "Files and Content",
    questions: [
      {
        hash: "whoOwns",
        title: "Do I still own my photos and videos?",
        answer:
          "Yes. You own 100% of your content. Upload My Stock is simply here to streamline the uploading process so you can get back out there and make more content!"
      },
      {
        hash: "fileTypes",
        title: "What types of files are supported?",
        answer:
          "We currently support: .mp4 ,.mov and .jpg files. We’re working on expanding this list to support more media files in the future."
      },
      {
        hash: "afterUpload",
        title: "What should I do after my upload is completed?",
        answer:
          "We recommend that you create a CSV file to upload to each agent. You can then go to the agent’s website to complete the final submission. "
      },
      {
        hash: "missingInAgent",
        title: "Why don’t I see my upload on the agent’s website?",
        answer:
          "Depending on the processing speed of the agents, it can take a few minutes for your media files to show up on their website. If you are experiencing problems, please contact us"
      }
      // {
      //   hash: "categoryMap",
      //   title:
      //     "Categories are different for different agents. How does Upload My Stock map categories",
      //   answer: "We make a close match. Below are the mappings we use"
      // }
    ]
  },
  {
    type: "Agents",
    questions: [
      {
        hash: "SupportedAgentsUpload",
        title: "What agents can I submit to through Upload My Stock?",
        answer: "You can upload to any agent that has an FTP."
      },
      {
        hash: "SupportedAgentscsv",
        title: "What agents can I generate CSV files for?",
        answer:
          "We currently support Shutterstock, Pond5, Adobe Stock, and BlackBox. More to come soon!"
      },
      {
        hash: "securePassword",
        title:
          "Why do I need to provide the password I use for my stock agent? How secure is this?",
        answer:
          "We need your password to upload your files and to authenticate your account. But don’t worry, your security is a top priority for us. We use state of the art encryption and hardware level security to protect your information." +
          "Also, by using a master password, we encrypt your original stock agency password, providing you with an extra layer of security. Your real password is not stored in our database."
      },
      {
        hash: "noAgentmembership",
        title:
          "What if I’m not a member of a stock agent like Shutterstock or Pond5?",
        answer:
          "No problem, there’s still a way to reap the benefits! You can create an account with BlackBox, an agency that acts as a mediator, distributing your files to all the major stock agencies. Find out more by clicking here",
        action: {
          link: "https://portal.blackbox.global/register?code=JHWE4A9D",
          text: "BlackBox (Referal Link)"
        }
      }
    ]
  }
];
