import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/storage");
require("firebase/auth");

const config = {
  apiKey: "AIzaSyDp7nVGpQd5q2yk_-_99O31A-4TDGOAnSA",
  authDomain: "uploadmystock-production.firebaseapp.com",
  databaseURL: "https://uploadmystock-production.firebaseio.com",
  projectId: "uploadmystock-production",
  storageBucket: "uploadmystock-production.appspot.com",
  messagingSenderId: "895649729410"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const storage = firebase.app().storage("gs://uploadmystock-production-uploads");
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

const PROJECT_ID = "uploadmystock-production";
const STRIPE_ID = "pk_live_KcgbaG6jnvvJf5OFBUY6kbRd"; //rename-file
export { firebase, firestore, storage, PROJECT_ID, STRIPE_ID };
