import React from 'react'
import Footer from '../../components/Footer'
import AppBar from '../../components/AppBar'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { questionSections } from '../../components/questions'
import { Button, Grid, TextField, CircularProgress } from '@material-ui/core'
import { firestore } from '../../firebase'
import mail from '../../images/mail.svg'
import './index.scss'
export default class Fqa extends React.Component {
  state = {
    name: '',
    email: '',
    questionDescription: '',
  }

  componentDidMount() {
    this.setState({ expanded: window.location.hash.replace(/#/, '') })
  }

  handleExpand = panel => (_, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  updateForm = field => e => {
    this.setState({
      [field]: e.target.value,
    })
  }

  submitMessage = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    await firestore.collection('messages').add({
      from: this.state.email,
      name: this.state.name,
      question: this.state.questionDescription,
    })
    this.setState({
      messageSent: true,
      loading: false,
      questionDescription: '',
      name: '',
      email: '',
    })
  }

  render() {
    const {
      expanded,
      loading,
      messageSent,
      name,
      email,
      questionDescription,
    } = this.state
    let hash = ''
    if (typeof window !== `undefined`) {
      hash = window.location.hash.replace(/#/, '')
    }
    return (
      <div className="faq-page">
        <AppBar />
        <div className="hero">
          <Typography variant="h4" gutterBottom>
            Common questions when considering Upload My Stock
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Please find your answers below and dont hesitate to contact us if
            you have any questions.
          </Typography>
        </div>
        <div className="section-content faq-content">
          {questionSections.map((section, i) => {
            return (
              <span key={i}>
                <Typography variant="h5" className="section-title">
                  {section.type}
                </Typography>
                {section.questions.map(question => {
                  return (
                    <ExpansionPanel
                      className={
                        question.hash === hash && expanded === question.hash
                          ? 'selected'
                          : ''
                      }
                      id={question.hash}
                      expanded={expanded === question.hash}
                      onChange={this.handleExpand(question.hash)}
                      key={question.hash}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{question.title}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography variant="body1">
                          {question.answer}{' '}
                          {question.action && (
                            <Button href={question.action.text}>
                              {question.action.text}
                            </Button>
                          )}{' '}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )
                })}
              </span>
            )
          })}
          <Typography variant="h5" gutterBottom className="contact-us-title">
            Need Help?
          </Typography>
          <Typography variant="subtitle1">
            Please dont hesitate to contact us.
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <img src={mail} className="mail-image" alt="contact us" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <form onSubmit={this.submitMessage}>
                <TextField
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={this.updateForm('name')}
                  margin="normal"
                  variant="outlined"
                  required
                />

                <TextField
                  type="email"
                  fullWidth
                  label="Email"
                  value={email}
                  onChange={this.updateForm('email')}
                  margin="normal"
                  variant="outlined"
                  required
                />

                <TextField
                  fullWidth
                  rows="4"
                  label="Detailed Question"
                  multiline
                  value={questionDescription}
                  onChange={this.updateForm('questionDescription')}
                  margin="normal"
                  helperText="Please give us a detailed description. Do not include any passwords, payments or any other sensitive information"
                  variant="outlined"
                  required
                />
                <Button type="submit" variant="outlined" color="primary">
                  Submit Question
                </Button>
                {messageSent && (
                  <Typography variant="subtitle2" className="message-sent-msg">
                    We recieved your message. We'll get back to you soon!{' '}
                  </Typography>
                )}
                {loading && (
                  <div className="loading">
                    <CircularProgress />
                    <Typography variant="h5">
                      Sending your message...
                    </Typography>
                  </div>
                )}
              </form>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}
